.logo img {
  padding: 20px;
}
.logo h2 {
  padding-bottom: 20px;
  font-weight: bold;
}
.nav-logo {
  width: 54px;
	height: 32px;
	
}
