.print-only {
  display: none;
}
@media print {
  .App .App > * {
    display: none;
  }

  .hide-print {
    display: none;
  }
  .dev-app {
    display: block !important;
  }
  .print-only {
    display: block !important;
  }

  .container {
    max-width: 100% !important;
    min-width: 0px !important;
  }
  body {
    min-width: 0px !important;
  }
  hr {
    display: none;
  }
  .main-container {
    min-height: auto !important;
    zoom: 70%;
  }
  #transaction {
    zoom: 100% !important;
  }
  body {
    counter-reset: chapternum figurenum;
  }
}
.product_desc {
  font-size: 19px;
}
